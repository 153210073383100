import type { AppProps } from 'next/app'

import Head from 'next/head'
import { appWithTranslation, Trans, useTranslation } from 'next-i18next'
import CookieConsent from 'react-cookie-consent'
import { TRPCWrapper } from '@goodee/trpc-user-lib/src/TRPCWrapper'
import { SessionProvider, useSession } from 'next-auth/react'

import '../styles/index.css'
import Link from 'next/link'
import React from 'react'
import Script from 'next/script'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

const ShopApp = ({ Component, pageProps }: AppProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Head>
        {/*Google Tag Manager (script block from Migros Aare)*/}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-WQJHS36');`,
          }}
        />
        {/* Hotjar Tracking Code for https://www.goodee.ch (script block from Hotjar)*/}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2658102,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        />
        <script src="https://cdn.seondf.com/js/v5/agent.js" async />
        <title>Goodee</title>
        <meta name="description" content="Goodee" />
        <meta
          name="facebook-domain-verification"
          content="289jxpy74173kcntngcwhmbafoul44"
        />
        <link rel="icon" href="/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        {/* <link rel="manifest" href="/site.webmanifest" /> */}
      </Head>
      <TRPCWrapper>
        <SessionProvider session={pageProps.session}>
          <Component {...pageProps} />

          <CookieConsent
            location="none"
            buttonText={t(`general.cookieBannerButton`)}
            style={{
              // set to '', otherwise tailwind styles are overridden
              background: '',
              width: '',
              left: '',
              borderWidth: '',
            }}
            containerClasses="print:!hidden drop-shadow-lg sm:left-10 mx-2 sm:mx-0 bottom-2 sm:bottom-3.5 bg-violet-10 border-violet-30 border-r rounded-xl border"
            buttonStyle={{
              background: '',
              color: '',
              borderRadius: '',
              padding: '',
              margin: '',
            }}
            buttonClasses="transition text-sm px-6 py-3 mt-0 sm:mt-4 m-4 sm:text-base rounded-md shadow-primary drop-shadow-lg bg-violet-100 h:hover:bg-violet-80 h:hover:shadow-primary-lg"
          >
            <div className="bg-violet-10 rounded-2xl">
              <span className="typography text-base">
                <Trans i18nKey="general.cookieBannerText">
                  <a
                    href="https://privacy.migros.ch/de.html"
                    target="_blank"
                    rel="noreferrer"
                  />
                </Trans>
              </span>
            </div>
          </CookieConsent>
        </SessionProvider>
      </TRPCWrapper>
    </>
  )
}

export default appWithTranslation(ShopApp)
