import _ from 'lodash'
import getConfig from 'next/config'

export type AppEnv = 'jest' | 'localdev' | 'dev' | 'test' | 'qa' | 'production'

export const getEnvVar = (key: string, defaultValue?: string): string => {
  const envVar = process.env[key]
  if (!_.isUndefined(envVar)) {
    return envVar
  } else if (!_.isUndefined(defaultValue)) {
    return defaultValue
  }
  throw new Error(`Required env variable ${key} undefined`)
}

export const getEnvVarNumber = (key: string, defaultValue?: string): number => {
  const envVar = Number(getEnvVar(key, defaultValue))
  if (isNaN(envVar)) {
    throw new Error(`env variable ${key} is not a number: ${getEnvVar(key)})`)
  }
  return envVar
}

export const getAppEnv = (): AppEnv => {
  return getFromEnvOrNextRuntimeConfig('APP_ENV', 'localdev')
}

export const getFromEnvOrNextRuntimeConfig = (
  key: string,
  defaultValue?: string
) => {
  if (!_.isUndefined(process.env[key])) {
    return process.env[key]
  }
  const nextConfig = getConfig()

  if (nextConfig?.publicRuntimeConfig?.[key]) {
    return nextConfig.publicRuntimeConfig[key]
  }

  return defaultValue
}

export const inBrowser = () => typeof window !== 'undefined'

type Env = Record<string, string>

declare global {
  // eslint-disable-next-line no-var
  var env: Env
}

export const initGlobalEnv = (env: Env) => {
  console.log(env)
  globalThis.env = env
}

export const getAppEnvPrefix = (hostname: string) =>
  getAppEnv() === 'production' ? hostname : getAppEnv() + '-' + hostname
