import { httpBatchLink } from '@trpc/client'
import superjson from 'superjson'
import type { CreateNextContextOptions } from '@trpc/server/adapters/next'
import { getSession } from 'next-auth/react'
import { getAppEnv, getEnvVar } from '@goodee/env-lib/src/app-env'

export function getBaseUrl() {
  if (typeof window !== 'undefined') {
    // In the browser, we return a relative URL
    return ''
  }
  // When rendering on the server, we return an absolute URL
  if (getAppEnv() === 'qa') {
    return 'https://qa-shop.goodee.ch'
  } else if (getAppEnv() === 'production') {
    return 'https://shop.goodee.ch'
  }

  // assume localhost
  return `http://goodee.local:${process.env.PORT ?? 3000}`
}

export const trpcClientConfig = () => ({
  links: [
    httpBatchLink({
      url: `${getBaseUrl()}/api/trpc`,
    }),
  ],
  transformer: superjson,
})

export const createContext = async (opts: CreateNextContextOptions) => {
  const session = await getSession({ req: opts.req })

  return {
    session: session || {},
  }
}
